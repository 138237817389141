<template>
    <div class="modal" ref="dtm-group-dialog" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <form v-on:submit.prevent="saveNewFolder">
                    <div class="modal-header">
                        <h5 class="modal-title fw-bold">{{ $t('새 폴더') }}</h5>
                        <button type="button" ref="dtmGroupModalClose" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="col-form-label fw-bold">{{ $t('폴더명') }}</label>
                            <input type="text" class="form-control" placeholder="폴더명을 입력하세요." v-model="saveItem.DTM_GROUP_NAME">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="close">{{ $t('취소') }}</button>
                        <button type="submit" class="btn btn-primary">{{ $t('저장') }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>


<script>
import {$session, $axios} from "dot-framework";
import Modal from "bootstrap/js/dist/modal";

export default {
    name: "CreateModal",
    data() {
        return {
            modal: null,
            saveItem: {
                DTM_GROUP_NO: "",
                DTM_GROUP_NAME: "",
                PARENT_GROUP_NO: "",
                USER_NO: "",
                DRIVER_KIND: ""
            },
        }
    },
    mounted() {
        const el = this.$refs["dtm-group-dialog"];
        this.modal = Modal.getOrCreateInstance(el);
    },
    unmounted() {
        this.modal = null
    },
    methods: {
        // 다이얼로그 열기
        openNewFolder() {
            this.saveItem.DTM_GROUP_NAME = "";
            this.saveItem.USER_NO = $session.getUserNo();
            this.saveItem.DRIVER_KIND = this.$store.getters['drive/driverKind'];
            this.modal.show();
        },
        // 다이얼로그 닫기
        close() {
            this.$refs.dtmGroupModalClose.click();
        },
        // 저장
        async saveNewFolder() {
            this.saveItem.PARENT_GROUP_NO = this.$route.query.parentGroupNo || "ROOT"
            const url = "/vision-app/v1/dtm/groups";
            const response = await $axios.post(url, this.saveItem);
            if (response.status === 201) {
                await this.$store.dispatch('drive/reloadItems', {parentGroupNo: this.$route.query.parentGroupNo});
                this.close();
            }
        }
    }
}
</script>

<style scoped>
.modal-content {
    border-radius: 40px;
    background: #F7F7F7;
}
.modal-header {
    justify-content: center;
    border-bottom: none;
    padding: 30px 0 0;
}
.modal-header h5 {
    font-size: 28px;
    line-height: 28px;
}
.modal-header button {
    display: none;
}
.modal-body {
    padding: 0 30px 10px;
}
.modal-body .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.modal-body .form-group label {
    font-size: 20px;
    color: #EA5414;
}
.modal-body .form-group input {
    height: 40px;
    font-size: 18px;
}
.modal-body .form-group input::placeholder {
    color: #AAABAB;
}
.modal-footer {
    border-top: none;
    justify-content: flex-end;
    padding-bottom: 30px;
}
.modal-footer button {
    font-size: 24px;
    width: 120px;
    border-radius: 30px;
    margin: 0 10px;
}
.btn-secondary {
    background: #AAABAB;
    border-color: #AAABAB;
}
.btn-secondary:active  {
    background: #c3c4c4;
    border-color: #c3c4c4;
}
.btn-primary {
    background: #EA5414;
    border-color: #EA5414;
}
.btn-primary:active  {
    background: #EB7340;
    border-color: #EB7340;
}
</style>
